<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Offers &amp; Deals Details</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="form-section">
                    <div class="offers-form-section roboto-regular">
                      <b-container>
                        <div>
                          <div class="d-flex">
                            <div class="title pink font-20 futuraStd-book pb-3">
                              Offers &amp; Deals
                            </div>
                            <div>
                              <b-form-checkbox
                                v-model="status"
                                value="accepted"
                                unchecked-value="not_accepted"
                                @input="updateHotDeals(status)"
                                >Hot Deals</b-form-checkbox
                              >
                            </div>

                            <div class="ml-3" v-if="details.offerType == 'Har Din Subh Hai'">
                              <b-form-checkbox
                                v-model="status1"
                                value="accepted"
                                unchecked-value="not_accepted"
                                @input="updateSubhOffer(status1)"
                                >Har Din Subh Hai</b-form-checkbox
                              >
                            </div>
                            <div class="ml-auto">
                              <b-button to="/deals-offers" class="back-btn">Back</b-button>
                            </div>
                          </div>
                          <b-form
                            v-promise-btn="{ action: 'submit' }"
                            @submit.prevent="saveOffersNDeals"
                          >
                            <!-- offer-details-section -->
                            <div class="pt-5">
                              <b-row>
                                <b-col md="8">
                                  <div class="roboto-bold font-16">
                                    OFFER DETAILS
                                  </div>
                                  <div class="form-details pt-3 gray1 font-14">
                                    <!-- name -->
                                    <div>
                                      <b-form-group>
                                        <label>Name of the offer</label>
                                        <b-form-input
                                          maxlength="20"
                                          :state="validateStateOffer('name')"
                                          aria-describedby="input-name-live-feedback"
                                          class="form-control w-50"
                                          v-model="$v.details.offer.name.$model"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-name-live-feedback"
                                          >Name is required.</b-form-invalid-feedback
                                        >
                                      </b-form-group>
                                    </div>
                                    <!--offer-period -->
                                    <div>
                                      <b-form-group>
                                        <label>Offer Period</label>
                                        <b-row no-gutters>
                                          <b-col md="4">
                                            <date-picker
                                              placeholder="Start"
                                              v-model="details.offer.offerPeriodStart"
                                              :format="format"
                                              :disabled-date="notBeforeToday"
                                            ></date-picker>
                                            <div
                                              v-if="
                                                !$v.details.offer.offerPeriodStart.required &&
                                                  $v.details.offer.offerPeriodStart.$error &&
                                                  $v.details.offer.offerPeriodStart.$touch
                                              "
                                              class="error-text py-1"
                                            >
                                              Offer Period Start is required
                                            </div>
                                          </b-col>

                                          <b-col md="4">
                                            <date-picker
                                              placeholder="End"
                                              v-model="details.offer.offerPeriodEnd"
                                              :format="format"
                                              :disabled-date="notBeforeFromDate"
                                            ></date-picker>
                                            <div
                                              v-if="
                                                !$v.details.offer.offerPeriodEnd.required &&
                                                  $v.details.offer.offerPeriodEnd.$error &&
                                                  $v.details.offer.offerPeriodEnd.$touch
                                              "
                                              class="error-text py-1"
                                            >
                                              Offer Period End is required
                                            </div>
                                          </b-col>
                                        </b-row>
                                      </b-form-group>
                                    </div>
                                    <!-- offer-valid-till -->
                                    <div>
                                      <b-form-group>
                                        <label>Offer Valid Till</label>
                                        <div>
                                          <date-picker
                                            placeholder
                                            v-model="details.offer.offerValidTill"
                                            :format="format"
                                            :disabled-date="notBeforeToday"
                                          ></date-picker>
                                          <div
                                            v-if="
                                              !$v.details.offer.offerValidTill.required &&
                                                $v.details.offer.offerValidTill.$error &&
                                                $v.details.offer.offerValidTill.$touch
                                            "
                                            class="error-text py-1"
                                          >
                                            Offer Valid Till is required
                                          </div>
                                        </div>
                                      </b-form-group>
                                    </div>
                                    <!-- weekend days -->
                                    <div>
                                      <b-form-group>
                                        <label>Include Weekend Dates</label>
                                        <b-row>
                                          <b-col md="4">
                                            <b-form-select
                                              v-model="$v.details.offer.includeWeekendDates.$model"
                                              :state="validateStateOffer('includeWeekendDates')"
                                              aria-describedby="input-includeWeekendDates-live-feedback"
                                              :options="dates"
                                            ></b-form-select>
                                            <b-form-invalid-feedback
                                              id="input-includeWeekendDates-live-feedback"
                                            >
                                              WeekendDates is required.
                                            </b-form-invalid-feedback>
                                          </b-col>
                                        </b-row>
                                      </b-form-group>
                                    </div>
                                    <!-- Currency -->
                                    <div>
                                      <b-form-group>
                                        <label>Currency</label>
                                        <b-row>
                                          <b-col md="4">
                                            <v-select
                                              v-model="details.offer.currency"
                                              label="name"
                                              :options="currency"
                                              return-object
                                            ></v-select>
                                            <div
                                              v-if="
                                                !$v.details.offer.currency.required &&
                                                  $v.details.offer.currency.$error &&
                                                  $v.details.offer.currency.$touch
                                              "
                                              class="error-text py-1"
                                            >
                                              Currency is required
                                            </div>
                                          </b-col>
                                        </b-row>
                                      </b-form-group>
                                    </div>
                                  </div>
                                </b-col>
                                <!-- Summer Package -->
                                <!-- <b-col md="4" class="pt-3">
                                  <b-card>
                                    <div>
                                      <div class="pink font-18 futuraStd-medium">
                                        {{ details.offer.name }} Package
                                      </div>
                                      <div class="d-flex roboto-bold justify-content-between pt-2">
                                        <div>
                                          Total Cost:
                                          {{ details.totalCost }}
                                        </div>
                                        <div>
                                          Discount Offered:
                                          {{ details.discountOffered }}%
                                        </div>
                                      </div>
                                      <div class="pt-2 font-16 roboto-bold">
                                        Offer Cost *:
                                      </div>
                                      <div class="pt-1 font-26 roboto-bold">
                                        {{ details.offer.currency.shortName
                                        }}{{ details.offerCost }}
                                      </div>
                                      <div class="gray roboto-bold pt-3">
                                        *Taxes will be Extra as applicable
                                        <div>**As per availability</div>
                                      </div>
                                    </div>
                                  </b-card>
                                </b-col> -->
                              </b-row>
                            </div>
                            <!-- nights and rooms -->
                            <div class="pt-5">
                              <div class="roboto-bold font-16">
                                NIGHTS &amp; ROOMS
                              </div>
                              <div class="gray1 font-14">
                                <!-- no of nights -->
                                <div class="pt-3">
                                  <b-form-group>
                                    <label>No. of Nights</label>
                                    <b-row>
                                      <b-col md="3">
                                        <b-form-select
                                          v-model="$v.details.nightsNRooms.noOfNights.$model"
                                          :state="validateStateRooms('noOfNights')"
                                          aria-describedby="input-noOfNights-live-feedback"
                                          :options="nights"
                                          @input="
                                            calculateNights(
                                              details.nightsNRooms.noOfNights,
                                              details.nightsNRooms.noOfRoomsPerNight
                                            )
                                          "
                                        ></b-form-select>
                                        <b-form-invalid-feedback
                                          id="input-noOfNights-live-feedback"
                                        >
                                          No Of Nights is required.
                                        </b-form-invalid-feedback>
                                      </b-col>
                                    </b-row>
                                  </b-form-group>
                                </div>
                                <!-- No of rooms per night -->
                                <div>
                                  <b-row>
                                    <b-col md="4">
                                      <div>
                                        <b-form-group>
                                          <label>No.of Rooms per Night</label>
                                          <b-form-input
                                            v-model="
                                              $v.details.nightsNRooms.noOfRoomsPerNight.$model
                                            "
                                            :state="validateStateRooms('noOfRoomsPerNight')"
                                            aria-describedby="input-noOfRoomsPerNight-live-feedback"
                                            @input="
                                              calculateNights(
                                                details.nightsNRooms.noOfNights,
                                                details.nightsNRooms.noOfRoomsPerNight
                                              )
                                            "
                                            class="form-control"
                                          ></b-form-input>
                                          <b-form-invalid-feedback
                                            id="input-noOfRoomsPerNight-live-feedback"
                                          >
                                            No Of Rooms per Night is required.
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                                <!-- Total no of nights -->
                                <div>
                                  <b-row>
                                    <b-col md="4">
                                      <div>
                                        <b-form-group>
                                          <label>Total No. of Nights</label>
                                          <b-form-input
                                            v-model="
                                              $v.details.nightsNRooms.totalNoOfNights.night.$model
                                            "
                                            :state="validateStateTotalNights('night')"
                                            aria-describedby="input-night-live-feedback"
                                            class="form-control"
                                          ></b-form-input>
                                          <b-form-invalid-feedback id="input-night-live-feedback">
                                            Total no of rooms is required.
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </div>
                                    </b-col>
                                    <b-col md="2" offset="2">
                                      <b-form-group>
                                        <div class="text-center">
                                          <label class="roboto-bold black">Qty</label>
                                        </div>
                                        <b-form-input
                                          readonly
                                          class="form-control"
                                          v-model="details.nightsNRooms.totalNoOfNights.night"
                                          @input="
                                            calculateNightCost(
                                              details.nightsNRooms.totalNoOfNights.night,
                                              details.nightsNRooms.totalNoOfNights.rate
                                            )
                                          "
                                        ></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="2">
                                      <b-form-group>
                                        <div class="text-center">
                                          <label class="roboto-bold black">Rate</label>
                                        </div>

                                        <b-form-input
                                          v-model="
                                            $v.details.nightsNRooms.totalNoOfNights.rate.$model
                                          "
                                          :state="validateStateTotalNights('rate')"
                                          @input="
                                            calculateNightCost(
                                              details.nightsNRooms.totalNoOfNights.night,
                                              details.nightsNRooms.totalNoOfNights.rate
                                            )
                                          "
                                          aria-describedby="input-rate-live-feedback"
                                          class="form-control"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-rate-live-feedback">
                                          Rate is required.
                                        </b-form-invalid-feedback>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="2">
                                      <b-form-group>
                                        <div class="text-center">
                                          <label class="roboto-bold black">Cost</label>
                                        </div>

                                        <b-form-input
                                          v-model="
                                            $v.details.nightsNRooms.totalNoOfNights.cost.$model
                                          "
                                          :state="validateStateTotalNights('cost')"
                                          aria-describedby="input-cost-live-feedback"
                                          class="form-control"
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="input-cost-live-feedback">
                                          Cost is required.
                                        </b-form-invalid-feedback>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </div>
                                <!-- No of guest -->
                                <div>
                                  <b-row>
                                    <b-col md="4">
                                      <div>
                                        <b-form-group>
                                          <label>No. of Guest</label>
                                          <b-form-input
                                            :state="validateCost('noOfGuest')"
                                            v-model="$v.details.noOfGuest.$model"
                                            aria-describedby="input-noOfGuest-live-feedback"
                                            class="form-control"
                                          ></b-form-input>
                                          <b-form-invalid-feedback
                                            id="input-noOfGuest-live-feedback"
                                          >
                                            No of Guest is required.
                                          </b-form-invalid-feedback>
                                        </b-form-group>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                                <!-- room type -->
                                <div>
                                  <b-row>
                                    <b-col md="4">
                                      <div>
                                        <b-form-group>
                                          <label>Room Type</label>
                                          <b-form-input
                                            readonly
                                            placeholder="Run of the House"
                                            class="form-control"
                                          ></b-form-input>
                                        </b-form-group>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                            <!-- meals section -->
                            <div class="pt-5">
                              <!-- meals -->
                              <div class="roboto-bold font-16">
                                <b-row>
                                  <b-col md="4">MEALS</b-col>
                                  <b-col offset="2" md="2">
                                    <div class="roboto-bold text-center">
                                      Pax
                                    </div>
                                  </b-col>
                                  <b-col md="2">
                                    <div class="roboto-bold text-center">
                                      Rate
                                    </div>
                                  </b-col>
                                  <b-col md="2">
                                    <div class="roboto-bold text-center">
                                      Cost
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                              <div class="gray1 font-14">
                                <!-- veg/non-veg -->
                                <div class="pt-4">
                                  <b-form-group>
                                    <b-row>
                                      <b-col md="2">
                                        <label class="pt-1">Veg / Non Veg</label>
                                      </b-col>
                                      <b-col md="2">
                                        <b-form-select
                                          :options="veg"
                                          v-model="details.meals.type"
                                          @change="checkValidation(details.meals.type)"
                                        ></b-form-select>
                                      </b-col>
                                    </b-row>
                                  </b-form-group>
                                </div>
                                <!-- plan -->
                                <div v-if="!hideMeal">
                                  <div>
                                    <b-form-group>
                                      <b-row>
                                        <b-col md="2">
                                          <label class="pt-1">Plan</label>
                                        </b-col>
                                        <b-col md="2">
                                          <b-form-select
                                            text-field="name"
                                            value-field="_id"
                                            :options="plan"
                                            v-model="details.meals.plan"
                                            @change="getAmount(details.meals.plan)"
                                          ></b-form-select>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                  </div>

                                  <div v-if="details.meals.plan">
                                    <b-form-group>
                                      <label>Breakfast (included in CP Plan)</label>
                                    </b-form-group>

                                    <b-row v-for="(meal, i) in details.meals.category" :key="i">
                                      <b-col md="4">
                                        <div>
                                          <b-form-group>
                                            <label>{{ meal.name }}</label>
                                          </b-form-group>
                                        </div>
                                      </b-col>

                                      <b-col md="2" offset="2">
                                        <b-form-group>
                                          <b-form-input
                                            v-model="meal.pax"
                                            class="form-control"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col md="2">
                                        <b-form-group>
                                          <b-form-input
                                            v-model="meal.rate"
                                            type="number"
                                            @input="calulateMealCost(meal.rate, i)"
                                            class="form-control"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col md="2">
                                        <b-form-group>
                                          <b-form-input
                                            v-model="meal.cost"
                                            class="form-control"
                                          ></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- venues -->
                            <div class="pt-5">
                              <div class="font-14 roboto-bold">
                                VENUES FOR FUNCTIONS**
                              </div>
                              <div class="pt-2">
                                <b-form-checkbox-group
                                  v-model="details.venuesForFunction"
                                  :options="venues"
                                  class="font-14 gray1"
                                  stacked
                                ></b-form-checkbox-group>
                              </div>
                            </div>
                            <!-- inclusion -->
                            <div class="pt-5">
                              <div class="roboto-bold font-14">INCLUSION</div>
                              <div class="pt-3 font-14 gray1">
                                <b-row v-for="item in inclusions" :key="item.key">
                                  <b-col md="6" class="pb-2">
                                    <div>
                                      <b-form-checkbox-group
                                        id="checkbox-group-2"
                                        v-model="tempInclusion"
                                        stacked
                                      >
                                        <b-form-checkbox
                                          class="font-14"
                                          :value="{
                                            inclusion: item._id,
                                            name: item.name
                                          }"
                                          >{{ item.name }}</b-form-checkbox
                                        >
                                      </b-form-checkbox-group>
                                    </div>
                                  </b-col>
                                  <b-col md="3">
                                    <b-form-group>
                                      <div v-if="item.name == 'Room Upgrades'">
                                        <b-form-select
                                          :options="inclusionRoom"
                                          v-model="item.comment"
                                        ></b-form-select>
                                      </div>
                                      <div v-if="item.name == 'Wedding Cake'">
                                        <b-form-select
                                          :options="inclusionCake"
                                          v-model="item.comment"
                                        ></b-form-select>
                                      </div>
                                      <div v-if="item.name == 'Butlers for Couple / Family'">
                                        <b-form-select
                                          :options="inclusionDrop"
                                          v-model="item.comment"
                                        ></b-form-select>
                                      </div>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                                <!-- <div class="pt-2 pb-2">
                            <b-button class="itemBtn">Add Inclusion</b-button>
                            </div>-->
                              </div>
                            </div>
                            <!-- wedding-package -->
                            <div class="pt-5">
                              <div class="roboto-bold font-14">
                                WEDDING PACKAGE BENEFITS
                              </div>
                              <div class="pt-3 font-14 gray1">
                                <b-row v-for="item in packages" :key="item.key">
                                  <b-col md="6" class="pb-2">
                                    <div>
                                      <b-form-checkbox-group
                                        id="checkbox-group-2"
                                        v-model="tempWeddingPackage"
                                        stacked
                                      >
                                        <b-form-checkbox
                                          class="font-14"
                                          :value="{
                                            name: item.name
                                          }"
                                          >{{ item.name }}</b-form-checkbox
                                        >
                                      </b-form-checkbox-group>
                                      <!-- <b-form-checkbox
                                        v-model="tempWeddingPackage"
                                        >{{ item.question }}</b-form-checkbox>-->
                                    </div>
                                  </b-col>
                                  <b-col md="3">
                                    <b-form-group>
                                      <div v-if="item.options">
                                        <b-form-select
                                          :options="weddings"
                                          v-model="item.comment"
                                        ></b-form-select>
                                      </div>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                                <!-- <div class="pt-2 pb-2">
                            <b-button class="itemBtn">Add Benefits</b-button>
                            </div>-->
                              </div>
                            </div>
                            <!-- cost -->
                            <div class="font-14">
                              <!-- total cost -->
                              <div>
                                <b-row>
                                  <b-col offset-md="6" md="3">
                                    <div class="roboto-bold gray1">
                                      Total Cost
                                    </div>
                                  </b-col>
                                  <b-col md="3">
                                    <b-form-input
                                      :state="validateCost('totalCost')"
                                      aria-describedby="input-totalCost-live-feedback"
                                      class="form-control"
                                      @change="
                                        calculateAmount(details.totalCost, details.discountOffered)
                                      "
                                      v-model="$v.details.totalCost.$model"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-totalCost-live-feedback">
                                      Total Cost is required
                                    </b-form-invalid-feedback>
                                  </b-col>
                                </b-row>
                              </div>
                              <!-- discount offered -->
                              <div class="pt-1">
                                <b-row>
                                  <b-col offset-md="6" md="3">
                                    <div class="roboto-bold gray1">
                                      Discount Offered
                                    </div>
                                  </b-col>
                                  <b-col md="3">
                                    <b-form-select
                                      :state="validateCost('discountOffered')"
                                      aria-describedby="input-discountOffered-live-feedback"
                                      class="form-control"
                                      v-model="$v.details.discountOffered.$model"
                                      @change="
                                        calculateAmount(details.totalCost, details.discountOffered)
                                      "
                                      :options="weddings"
                                    ></b-form-select>
                                    <b-form-invalid-feedback
                                      id="input-discountOffered-live-feedback"
                                    >
                                      Discount Offered is required
                                    </b-form-invalid-feedback>
                                  </b-col>
                                </b-row>
                              </div>
                              <!-- total amount -->
                              <div class="pt-1">
                                <b-row>
                                  <b-col offset-md="6" md="3">
                                    <div class="gray1 roboto-bold">
                                      Total amount
                                    </div>
                                  </b-col>
                                  <b-col md="3">
                                    <b-form-input
                                      :state="validateCost('totalAmount')"
                                      aria-describedby="input-totalAmount-live-feedback"
                                      class="form-control"
                                      v-model="$v.details.totalAmount.$model"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-totalAmount-live-feedback">
                                      Total Amount is required
                                    </b-form-invalid-feedback>
                                  </b-col>
                                </b-row>
                              </div>
                              <!-- offer cost -->
                              <div class="pt-1">
                                <b-row>
                                  <b-col offset-md="6" md="3">
                                    <div class="roboto-bold">Offer Cost</div>
                                  </b-col>
                                  <b-col md="3">
                                    <b-form-input
                                      :state="validateCost('offerCost')"
                                      aria-describedby="input-offerCost-live-feedback"
                                      class="form-control"
                                      v-model="$v.details.offerCost.$model"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="input-offerCost-live-feedback">
                                      Offer Cost is required
                                    </b-form-invalid-feedback>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                            <!-- button-section -->
                            <div class="py-5">
                              <div class="d-flex justify-content-center">
                                <div>
                                  <b-button type="submit" class="saveBtn">Update</b-button>
                                </div>
                                <!-- <div>
                                  <b-button @click="showDetails()" class="itemBtn ml-3"
                                    >Preview</b-button
                                  >
                                </div> -->
                                <div>
                                  <b-button @click="onCancel()" class="itemBtn ml-3"
                                    >Cancel</b-button
                                  >
                                </div>

                                <!-- <popup v-if="showPopUp" :modalContent="modalId3" /> -->
                              </div>
                            </div>
                          </b-form>
                        </div>
                      </b-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import currency from "@/js/currency";
import _ from "lodash";
const today = new Date();
today.setHours(0, 0, 0, 0);

export default {
  components: {
    headerCustom,
    DatePicker
  },
  data() {
    return {
      hotDealsDetails: "",
      status: "not_accepted",
      subhOfferDetails: "",
      status1: "not_accepted",
      details: {
        offer: {
          name: "",
          offerPeriodStart: "",
          offerPeriodEnd: "",
          offerValidTill: "",
          includeWeekendDates: "",
          currency: {
            name: "",
            shortName: "",
            symbol: ""
          }
        },
        nightsNRooms: {
          noOfNights: "",
          noOfRoomsPerNight: "",
          totalNoOfNights: {
            night: "",
            rate: "",
            cost: ""
          }
        },
        venuesForFunction: [],
        inclusion: [],
        weddingPackage: [],
        meals: {
          type: "",
          plan: "",
          category: []
        },
        totalCost: null,
        discountOffered: null,
        totalAmount: null,
        offerCost: null,
        noOfGuest: null,
        offerType: "Deals and Offers"
      },
      tempWeddingPackage: [],
      tempInclusion: [],
      comment: "",
      inclusionCake: [],
      inclusionDrop: [],
      inclusionRoom: [],
      amount: [],
      totalRoom: 0,
      show: false,
      selected9: null,
      format: "MMMM DD YY",
      dates: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" }
      ],
      currency: currency,
      weddings: [],
      nights: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" }
      ],
      veg: [
        { text: "No Option", value: null },
        { text: "Veg.", value: "Veg" },
        { text: "Non-Veg.", value: "Non-Veg." }
      ],
      plan: [],
      venues: [
        {
          text: "Indoor / Outdoor Venues (Large Functions)",
          value: "Indoor / Outdoor Venues (Large Functions)"
        },
        {
          text: "Small Banquets / Venues (Small Functions)",
          value: "Small Banquets / Venues (Small Functions)"
        },
        { text: "Storage Room", value: "Storage Room" }
      ],
      inclusions: [],
      packages: [
        {
          name: "Discount on Ironing & Laundry",
          options: [{ value: "null", text: "25%" }],
          answer: ""
        },
        {
          name: "Discount on Spa",
          options: [{ value: "null", text: "30%" }],
          answer: ""
        },
        {
          name: "Discount on Salon",
          options: [{ value: "null", text: "30%" }],
          answer: ""
        },
        {
          name: "Private check-in desk",
          answer: ""
        },
        {
          name: "Private Hospitality desk for Group",
          answer: ""
        },
        {
          name: "Honeymoon Suit for Couple with room decor",
          answer: ""
        }
      ],
      discount: [{ text: "30%", value: "null" }],
      form: {
        fromDate: "",
        toDate: "",
        name: "",
        selected: null,
        selected1: null,
        selected2: null
      },
      offerId: "",
      showPopUp: false,
      totalNights: 0,
      totalMeals: 0,
      total: 0,
      hideMeal: false
    };
  },
  validations: {
    details: {
      offer: {
        name: {
          required
        },
        offerPeriodStart: {
          required
        },
        offerPeriodEnd: {
          required
        },
        offerValidTill: {
          required
        },
        includeWeekendDates: {
          required
        },
        currency: {
          required
        }
      },
      nightsNRooms: {
        noOfNights: {
          required
        },
        noOfRoomsPerNight: {
          required
        },
        totalNoOfNights: {
          night: {
            required
          },
          rate: {
            required
          },
          cost: {
            required
          }
        }
      },

      totalCost: {
        required
      },
      discountOffered: {
        required
      },
      totalAmount: {
        required
      },
      offerCost: {
        required
      },
      noOfGuest: {
        required
      }
    }
  },
  mounted() {
    service.getFoodPackages(menuResult => {
      this.plan = menuResult.data;
    });
    if (this.$route.params.id) {
      service.getOneOffer(this.$route.params.id, result => {
        if (result.status == 200) {
          this.details = result.data.result;
          if (this.details.hotDeals) {
            this.status = "accepted";
          } else {
            this.status = "not_accepted";
          }
          this.hotDealsDetails = this.status;

          if (this.details.harDinSubhHai) {
            this.status1 = "accepted";
          } else {
            this.status1 = "not_accepted";
          }
          this.subhOfferDetails = this.status1;

          if (this.details.meals.type == null) {
            this.hideMeal = true;
          } else {
            this.details.meals.plan = result.data.result.meals.plan._id;
          }

          this.details.offer.offerPeriodStart = new Date(result.data.result.offer.offerPeriodStart);
          this.details.offer.offerPeriodEnd = new Date(result.data.result.offer.offerPeriodEnd);
          this.details.offer.offerValidTill = new Date(result.data.result.offer.offerValidTill);
          // this.format = 'MMMM DD YY'
        }
      });
      service.getInclusions(
        {
          type: "Offer"
        },
        Result => {
          this.inclusions = Result.data;
          this.tempInclusion = this.details.inclusion;
          _.each(this.tempInclusion, myGeneral => {
            if (myGeneral.comment) {
              const index = _.findIndex(this.inclusions, ["_id", myGeneral.inclusion]);
              this.inclusions[index].comment = myGeneral.comment;
              delete myGeneral.comment;
              delete myGeneral._id;
            } else {
              delete myGeneral._id;
            }
          });
          this.tempWeddingPackage = this.details.weddingPackage;
          _.each(this.tempWeddingPackage, myGeneral => {
            if (myGeneral.comment) {
              const index = _.findIndex(this.packages, ["name", myGeneral.name]);
              this.packages[index].comment = myGeneral.comment;
              delete myGeneral._id;
              delete myGeneral.comment;
            } else {
              delete myGeneral._id;
            }
          });
        }
      );
    }
    for (i = 1; i < 15; i++) {
      this.inclusionRoom.push({ text: i, value: i });
    }
    for (var i = 1; i < 7; i++) {
      this.inclusionDrop.push({ text: i, value: i });
    }
    for (i = 1; i < 16; i++) {
      this.inclusionCake.push({ text: i + "kg", value: i + "kg" });
    }
    for (i = 1; i < 21; i++) {
      this.weddings.push({ text: 5 * i + "%", value: 5 * i });
    }
  },
  methods: {
    notBeforeToday(date) {
      return date < today;
    },
    notBeforeFromDate(date) {
      return date <= this.details.offer.offerPeriodStart;
    },
    showDetails() {
      this.$router.push({
        name: "offers-details-offer",
        params: {
          offer: this.$route.params.offer
        }
      });
    },
    validateStateOffer(name) {
      const { $dirty, $error } = this.$v.details.offer[name];
      return $dirty ? !$error : null;
    },
    validateStateRooms(name) {
      const { $dirty, $error } = this.$v.details.nightsNRooms[name];
      return $dirty ? !$error : null;
    },
    validateStateTotalNights(name) {
      const { $dirty, $error } = this.$v.details.nightsNRooms.totalNoOfNights[name];
      return $dirty ? !$error : null;
    },

    validateCost(name) {
      const { $dirty, $error } = this.$v.details[name];
      return $dirty ? !$error : null;
    },
    checkValidation(mealType) {
      if (mealType == null) {
        this.hideMeal = true;
        this.details.meals.plan = null;
        this.details.meals.category = [];
        this.details.totalCost =
          this.details.nightsNRooms.totalNoOfNights.night *
          this.details.nightsNRooms.totalNoOfNights.rate;
        this.calculateAmount(this.details.totalCost, this.details.discountOffered);
      } else {
        this.hideMeal = false;
      }
    },
    getAmount(data) {
      this.details.meals.category = [];
      var lunch = {};
      lunch.name = "Lunch";
      lunch.pax = this.details.noOfGuest * this.details.nightsNRooms.noOfNights;
      lunch.rate = "";
      lunch.cost = "";
      this.details.meals.category.push(lunch);
      var hiTea = {};
      hiTea.name = "Hi-Tea";
      hiTea.pax = this.details.noOfGuest * this.details.nightsNRooms.noOfNights;
      hiTea.rate = "";
      hiTea.cost = "";
      this.details.meals.category.push(hiTea);
      var dinner = {};
      dinner.name = "Dinner";
      dinner.pax = this.details.noOfGuest * this.details.nightsNRooms.noOfNights;
      dinner.rate = "";
      dinner.cost = "";
      this.details.meals.category.push(dinner);
      this.calculateNightCost(
        this.details.nightsNRooms.totalNoOfNights.night,
        this.details.nightsNRooms.totalNoOfNights.rate
      );
      this.calculateAmount(this.details.totalCost, this.details.discountOffered);
    },
    calulateMealCost(rate, index) {
      this.details.meals.category[index].rate = rate;
      this.details.meals.category[index].cost =
        this.details.meals.category[index].pax * this.details.meals.category[index].rate;

      this.total = 0;
      var sum = 0;
      for (var i = 0; i < this.details.meals.category.length; i++) {
        if (this.details.meals.category[i].cost) {
          sum += Number(this.details.meals.category[i].cost);
        }
      }

      if (sum) {
        this.totalMeals = sum;
        this.details.totalCost =
          Number(this.totalMeals == 0 ? this.total : this.totalMeals) +
          Number(this.details.nightsNRooms.totalNoOfNights.cost);
      }
      this.calculateAmount(this.details.totalCost, this.details.discountOffered);
    },
    saveOffersNDeals() {
      this.$v.details.$touch();
      if (this.$v.details.$anyError) {
        return;
      } else if (this.tempInclusion.length < 1) {
        this.$toaster.error("Select atleast 1 Inclusion");
      } else if (this.tempWeddingPackage.length < 1) {
        this.$toaster.error("Select atleast 1 Wedding Package");
      } else {
        this.details.inclusion = [];
        _.each(this.inclusions, data => {
          _.each(this.tempInclusion, temp => {
            if (data._id == temp.inclusion) {
              this.details.inclusion.push({
                inclusion: data._id,
                name: data.name,
                comment: data.comment
              });
            }
          });
        });
        this.details.weddingPackage = [];
        _.each(this.packages, data => {
          _.each(this.tempWeddingPackage, temp => {
            if (data.name == temp.name) {
              this.details.weddingPackage.push({
                name: data.name,
                comment: data.comment
              });
            }
          });
        });
        return new Promise((resolve, reject) => {
          service.updateOfferNDealForAdmin(this.details, this.$route.params.id, result => {
            if (result) {
              this.offerId = result.data._id;
              this.$toaster.success("Offer and Deals Updated.");
              this.show = true;
              resolve();
            } else {
              reject();
            }
          });
        });
      }
      // }
    },
    onCancel() {
      var data = {};
      data.offerId = this.$route.params.id;
      service.cancelOffer(data, result => {
        if (result.status == 200) {
          this.$toaster.success("Offer Not Active");
          this.$router.push("/deals-offers");
        }
      });
    },
    calculateNights(night, roomsPerNight) {
      this.details.noOfNights = night;
      if (night && roomsPerNight) {
        this.details.nightsNRooms.totalNoOfNights.night = night * roomsPerNight;
        this.details.noOfGuest = roomsPerNight * 2;
      }
      this.calculateNightCost(
        this.details.nightsNRooms.totalNoOfNights.night,
        this.details.nightsNRooms.totalNoOfNights.rate
      );
      this.calculteMealCostAfterChange();
      this.calculateAmount(this.details.totalCost, this.details.discountOffered);
    },
    calculteMealCostAfterChange() {
      this.total = 0;
      var sum = 0;
      for (var i = 0; i < this.details.meals.category.length; i++) {
        this.details.meals.category[i].pax =
          this.details.noOfGuest * this.details.nightsNRooms.noOfNights;
        this.details.meals.category[i].cost =
          this.details.meals.category[i].pax * this.details.meals.category[i].rate;
        if (this.details.meals.category[i].cost) {
          sum += Number(this.details.meals.category[i].cost);
        }
      }

      if (sum) {
        this.totalMeals = sum;
        this.details.totalCost =
          Number(this.totalMeals == 0 ? this.total : this.totalMeals) +
          Number(this.details.nightsNRooms.totalNoOfNights.cost);
      }
    },
    calculateNightCost(qty, rate) {
      if (qty && rate) {
        this.details.nightsNRooms.totalNoOfNights.cost = qty * rate;
      }
      var sum = 0;
      this.details.totalCost = Number(
        this.details.nightsNRooms.totalNoOfNights.night *
          this.details.nightsNRooms.totalNoOfNights.rate
      );
    },

    calculateAmount(cost, offer) {
      var data = (cost * offer) / 100;
      var amount = Math.ceil(cost - data);
      this.details.totalAmount = amount;
    },
    updateHotDeals(hotDealsData) {
      if (this.hotDealsDetails != hotDealsData) {
        const offerId = this.$route.params.id;
        if (this.status === "accepted") {
          this.details.hotDeals = true;
        } else {
          this.details.hotDeals = false;
        }
        const dataToUpdate = {
          hotDeals: this.details.hotDeals,
          id: offerId
        };
        service.updateHotDeals(dataToUpdate, result => {
          this.$toaster.success("Hot Deals Updated");
        });
      }
    },
    updateSubhOffer(subhOfferData) {
      if (this.subhOfferDetails != subhOfferData) {
        const offerId = this.$route.params.id;
        if (this.status1 === "accepted") {
          this.details.harDinSubhHai = true;
        } else {
          this.details.harDinSubhHai = false;
        }
        const dataToUpdate = {
          harDinSubhHai: this.details.harDinSubhHai,
          id: offerId
        };

        service.updateSubhOffer(dataToUpdate, result => {
          this.$toaster.success("Har Din Subh Hai Updated");
        });
      }
    }
  }
};
</script>

<style lang="scss">
.offers-form-section {
  .back-btn {
    // background: $gray;
    padding: 3px 40px;
    border: 0px;
    &:hover {
      // background: $purple;
    }
  }

  .card {
    border: 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.17);
    border-radius: 15px;
  }
  .custom-select {
    height: calc(1.5em + 0.38rem + 2px);
  }
  .saveBtn {
    // background: $green;
    padding: 4px 50px;
    border: 0px;
    &:hover {
      // background: $purple;
    }
  }
  .itemBtn {
    padding: 4px 30px;
    border: 0px;
    &:hover {
      // background: $purple;
    }
  }
  .error-text {
    color: red;
    font-size: 10px;
  }
}
@import "vue-select/src/scss/vue-select.scss";
</style>
